import React, { useCallback, useEffect, useState } from "react";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import AddIcon from "@mui/icons-material/Add";
import ShareIcon from "@mui/icons-material/Share";
import LiveHelpIcon from "@mui/icons-material/LiveHelp";
import PrintIcon from "@mui/icons-material/Print";
import LabelIcon from "@mui/icons-material/Label";
import { brandGrey } from "theme/colors";
import { ComponentType, IComponent, ISecondaryCourse, IState, TemplateStage, UserMode } from "store/types";
import SettingsIcon from "@mui/icons-material/Settings";
import { useDispatch, useSelector } from "react-redux";
import {
  addYear,
  autoAssignComponentSubjects,
  changeMMSEnrollment,
  changeTemplateState,
  flipShowPlaceholders,
  resetAllHints,
} from "actions/enrollment";
import { ControlledMenu, MenuItem, SubMenu } from "@szhsin/react-menu";
import "@szhsin/react-menu/dist/index.css";
import { capitalize, intersection } from "lodash";
import "../../actionMenu.css";
import { AutoAssignCoreDialog } from "../../../components/autoAssignCore/AutoAssignCoreDialog";
import { AutoAssignmentSummaryDialog } from "../../../components/autoAssignCore/AutoAssignmentSummaryDialog";
import { usePlanPermissions } from "lib/usePlanPermissions";
import { Check } from "@mui/icons-material";
import { ChangeNameDialog } from "../ChangeNameDialog";
import { EditNotesDialog } from "../EditNotesDialog";
import DeleteIcon from "@mui/icons-material/Delete";
import { DeleteTemplateDialog } from "../DeleteTemplateDialog";
import { isMMSEnabled } from "lib/mms";
import { DiplomasSubMenu } from "./DiplomasSubMenu";
import { MajorsSubMenu } from "./MajorsSubMenu";
import { MinorsSubMenu } from "./MinorsSubMenu";
import { SpecialisationsSubMenu } from "./SpecialisationsSubMenu";
import { AdvancedStandingSubMenu } from "./AdvanceStandingsSubMenu";
import { featureToggles } from "../../../config/featureToggles";
import { getAllCompEligibility, getAllDiscontinuedMMS } from "../../../actions/admin";
import { SampleCoursePlanDialog } from "../SampleCoursePlanDialog";
import difference from "lodash/difference";
import { ReactComponent as DuplicatePlanIcon } from "./DuplicatePlanIcon.svg";
import { ReactComponent as AddSubjectsIcon } from "./AddSubjectsIcon.svg";
import { Icon } from "@mui/material";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { ClearAllTemplateDialog } from "../ClearAllTemplateDialog";
import { DuplicateTemplateDialog } from "../DuplicateTemplateDialog";
import { ReactComponent as ViewCourseIcon } from "./ViewCourseIcon.svg";
import { ImportSubjectsDialog } from "containers/importSubjects/ImportSubjectsDialog";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { isGraduateCourse } from "lib/course";

interface IProps {
  closeMenu: () => any;
  anchorRef: React.Ref<any>;
  anchorPoint: any;
  openDialog: () => any;
  majorsAndMinors: IComponent[];
  specialisations: IComponent[];
  courseSpecialisations: IComponent[];
}

export const PlanActionMenu = (props: IProps) => {
  const {
    closeMenu,
    openDialog,
    anchorRef,
    anchorPoint,
    majorsAndMinors,
    specialisations,
    courseSpecialisations,
    ...menuProps
  } = props;
  const majors = majorsAndMinors.filter((m: IComponent) => m.type === ComponentType.MAJOR);
  const minors = majorsAndMinors.filter((m: IComponent) => m.type === ComponentType.MINOR);
  const dispatch = useDispatch();
  const enrollment = useSelector((state: IState) => state.enrollment!);
  const isUndergrad = !isGraduateCourse(enrollment.course);
  const profile = useSelector((state: IState) => state.profile);
  const { readOnlyPlan, userMode, adminPath, viewOnlyPlan } = usePlanPermissions();
  const { plan, course, allowedComponents, allowedSecondaryCourses } = enrollment;
  const { mmsRecordIds, id: planId, secondaryCourses } = plan;

  const diplomas = allowedSecondaryCourses;

  const isComponentEnabled = useCallback(
    (c: IComponent) => isMMSEnabled(c, allowedComponents, mmsRecordIds, course.mmsLimits),
    [allowedComponents, course.mmsLimits, mmsRecordIds],
  );

  // Manage the state of the confirmation dialog
  const [autoAssignRecordIds, setAutoAssignRecordIds] = useState<string[] | null>(null);

  const closeAutoAssignModal = useCallback(() => setAutoAssignRecordIds(null), []);
  const openAutoAssignModal = useCallback((recordIds: string[]) => setAutoAssignRecordIds(recordIds), []);

  // Manage the state of the summary dialog
  const [autoAssignmentResult, setAutoAssignmentResult] = useState<any>(null);
  const closeAutoAssignmentSummaryModal = useCallback(() => setAutoAssignmentResult(null), []);
  const openAutoAssignmentSummaryModal = useCallback((result: any) => setAutoAssignmentResult(result), []);

  // Manage the state of the change name dialog
  const [changeNameDialogOpen, setChangeNameDialogOpen] = useState(false);
  const flipChangeNameDialog = useCallback(() => setChangeNameDialogOpen((old) => !old), []);

  const [editNotesDialogOpen, setEditNotesDialogOpen] = useState(false);
  const flipEditNotesDialog = useCallback(() => setEditNotesDialogOpen((old) => !old), []);

  const [deleteTemplateDialogOpen, setDeleteTemplateDialogOpen] = useState(false);
  const flipDeleteTemplateDialog = useCallback(() => setDeleteTemplateDialogOpen((old) => !old), []);

  const [sampleCoursePlanOpen, setSampleCoursePlanOpenOpen] = useState(false);
  const flipSampleCoursePlan = useCallback(() => setSampleCoursePlanOpenOpen((old) => !old), []);

  // Manage the state of the clear plan
  const [clearAllTemplateDialogOpen, setClearAllTemplateDialogOpen] = useState(false);
  const flipClearAllTemplateDialog = useCallback(() => {
    setClearAllTemplateDialogOpen((old) => !old);
  }, []);

  // Manage the state of the duplicate plan
  const [duplicateTemplateDialogOpen, setDuplicateTemplateDialogOpen] = useState(false);
  const flipDuplicateTemplateDialog = useCallback(() => {
    setDuplicateTemplateDialogOpen((old) => !old);
  }, []);

  // Manage the state of the import subjects
  const [importSubjectsDialogOpen, setImportSubjectsDialogOpen] = useState(false);
  const flipImportSubjectsDialog = useCallback(() => {
    setImportSubjectsDialogOpen((old) => !old);
  }, []);

  // Manage opening course in handbook
  const handleOpenCourse = useCallback(() => {
    if (enrollment) {
      window.open(
        `https://handbook.unimelb.edu.au/${
          (enrollment as any).plan.year
        }/courses/${enrollment.course.code.toLowerCase()}/print`,
        "_blank",
      );
    } else {
      window.open("https://handbook.unimelb.edu.au", "_blank");
    }
  }, [enrollment]);

  // Selected in plan
  const selectedSpecialisations = specialisations
    .filter((s: IComponent) => mmsRecordIds.includes(s.recordId))
    .map((s: IComponent) => s.recordId);

  const selectedMajors = majors.filter((s: IComponent) => mmsRecordIds.includes(s.recordId));

  const selectedMinors = minors.filter((s: IComponent) => mmsRecordIds.includes(s.recordId));

  const selectedDiplomas: any = (diplomas ?? []).filter(
    (d: any) => !!secondaryCourses?.find((s: ISecondaryCourse) => s.courseRecordId === d.recordId),
  );

  if (
    !!featureToggles.componentEligibility &&
    secondaryCourses &&
    secondaryCourses.length === 1 &&
    secondaryCourses[0].mmsRecordIds &&
    secondaryCourses[0].mmsRecordIds.length === 1
  ) {
    const diplomaAllowedComponents = selectedDiplomas[0]?.allowedComponents;

    selectedDiplomas[0].selectedSecondCourseSpecialisations = diplomaAllowedComponents?.filter(
      (c: any) => c.recordId === secondaryCourses[0].mmsRecordIds[0],
    );
  }

  // Manage the state of showing placeholders on the plan
  const flipPlaceholders = useCallback(() => {
    planId && dispatch(flipShowPlaceholders(planId, !plan.showPlaceholders));
  }, [dispatch, planId, plan.showPlaceholders]);

  const doAddYear = useCallback(
    (_e: React.MouseEvent) => {
      return planId && dispatch(addYear({ planId }));
    },
    [dispatch, planId],
  );

  const doResetHints = useCallback(
    (_e: React.MouseEvent) => {
      dispatch(resetAllHints());
    },
    [dispatch],
  );
  const doPrint = useCallback(
    (_e: React.MouseEvent) => {
      closeMenu();
      window.print();
    },
    [closeMenu],
  );
  const doAutoAssign = useCallback(async () => {
    if (autoAssignRecordIds && autoAssignRecordIds.length > 0) {
      closeAutoAssignModal();
      const autoAssignAction: any = dispatch(autoAssignComponentSubjects(planId, autoAssignRecordIds));
      const autoAssignmentResult = await autoAssignAction;
      openAutoAssignmentSummaryModal(autoAssignmentResult.value.autoAsignmentResult);
    }
  }, [autoAssignRecordIds, closeAutoAssignModal, dispatch, planId, openAutoAssignmentSummaryModal]);

  const doChangeMSSEnrollment = useCallback(
    (mmsRecordIds: string[]) => async (_e: React.MouseEvent) => {
      let hasMatchingMajorSamplePlan = false;
      if (planId) {
        if (!!featureToggles.sampleTemplate) {
          const planMMRecordIds = enrollment?.plan.mmsRecordIds || [];
          const newMMSRecordIds = [
            ...difference(planMMRecordIds, mmsRecordIds),
            ...difference(mmsRecordIds, planMMRecordIds),
          ];

          hasMatchingMajorSamplePlan = !!enrollment?.matchingTemplates?.find((mt: any) => {
            return !!mt.mmsRecordIds.find((r: string) => newMMSRecordIds.includes(r));
          });

          if (hasMatchingMajorSamplePlan) {
            setSampleCoursePlanOpenOpen(true);
          }
        }
        const changeAction: any = dispatch(changeMMSEnrollment(planId, mmsRecordIds));
        const result = await changeAction;
        const assignableCore = result.value.assignableCore || [];
        const toAction = intersection(assignableCore, mmsRecordIds);
        if (toAction.length > 0) {
          if (!!featureToggles.sampleTemplate && hasMatchingMajorSamplePlan) {
            dispatch(autoAssignComponentSubjects(planId, toAction));
          } else {
            openAutoAssignModal(toAction);
          }
        }
      }
    },
    [planId, dispatch, enrollment?.plan.mmsRecordIds, enrollment?.matchingTemplates, openAutoAssignModal],
  );

  const doChangeTemplateState = useCallback(
    (state: TemplateStage) => async (_e: React.MouseEvent) => {
      if (planId && state !== plan.template?.state) {
        dispatch(changeTemplateState(state, planId));
      }
    },
    [planId, plan.template?.state, dispatch],
  );

  useEffect(() => {
    if (featureToggles.componentEligibility) {
      dispatch(getAllCompEligibility());
    }

    if (featureToggles.discontinuedMMS) {
      dispatch(getAllDiscontinuedMMS());
    }
  }, [dispatch]);

  const menuItems = [
    !readOnlyPlan && !viewOnlyPlan && (
      <MenuItem onClick={flipChangeNameDialog} key="change-name-menu-item">
        <ListItemIcon style={{ color: brandGrey.toHexString() }}>
          <LabelIcon />
        </ListItemIcon>
        <ListItemText primary="Change name" />
      </MenuItem>
    ),
    userMode === UserMode.ADMIN && adminPath && plan.template && (
      <MenuItem onClick={flipEditNotesDialog} key="edit-notes-menu-item">
        <ListItemIcon style={{ color: brandGrey.toHexString() }}>
          <LabelIcon />
        </ListItemIcon>
        <ListItemText primary="Edit notes" />
      </MenuItem>
    ),

    userMode === UserMode.ADMIN && adminPath && plan.template && (
      <SubMenu
        key={`template-change-state`}
        aria-label={`Change plan template state`}
        label={() => (
          <React.Fragment>
            <ListItemIcon style={{ color: brandGrey.toHexString() }}>
              <SettingsIcon />
            </ListItemIcon>
            <ListItemText primary="Change Template State" />
          </React.Fragment>
        )}
      >
        {Object.values(TemplateStage).map((state: TemplateStage) => (
          <MenuItem
            key={`$template-state-${state}`}
            tabIndex={-1}
            type="checkbox"
            checked={plan?.template?.state === state}
            onClick={doChangeTemplateState(state)}
          >
            <ListItemIcon>{plan?.template?.state === state && <Check />}</ListItemIcon>
            <ListItemText
              // className={sp.disabled ?  "menu-list-item-disabled" : undefined} not used so that users using assistive tech can still tab to it
              primary={capitalize(state)}
            />
          </MenuItem>
        ))}
      </SubMenu>
    ),

    <MajorsSubMenu
      key="major-menu-component"
      enrollment={enrollment}
      majorsAndMinors={majorsAndMinors}
      specialisations={specialisations}
      selectedMinors={selectedMinors}
      selectedDiplomas={selectedDiplomas}
      selectedSpecialisations={selectedSpecialisations}
      doChangeMSSEnrollment={doChangeMSSEnrollment}
      isComponentEnabled={isComponentEnabled}
    />,

    <MinorsSubMenu
      key="minor-menu-component"
      enrollment={enrollment}
      majorsAndMinors={majorsAndMinors}
      specialisations={specialisations}
      selectedMajors={selectedMajors}
      selectedSpecialisations={selectedSpecialisations}
      selectedDiplomas={selectedDiplomas}
      doChangeMSSEnrollment={doChangeMSSEnrollment}
      isComponentEnabled={isComponentEnabled}
    />,

    <SpecialisationsSubMenu
      key="specialisation-menu-component"
      enrollment={enrollment}
      courseSpecialisations={courseSpecialisations}
      isComponentEnabled={isComponentEnabled}
      doChangeMSSEnrollment={doChangeMSSEnrollment}
    />,

    <DiplomasSubMenu
      key="diploma-menu-component"
      enrollment={enrollment}
      selectedMajors={selectedMajors}
      selectedMinors={selectedMinors}
      openAutoAssignModal={openAutoAssignModal}
    />,

    !readOnlyPlan && !viewOnlyPlan && (
      <MenuItem onClick={doAddYear} key="add-year-menu-item">
        <ListItemIcon style={{ color: brandGrey.toHexString() }}>
          <AddIcon />
        </ListItemIcon>
        <ListItemText primary="Add another year" />
      </MenuItem>
    ),

    <AdvancedStandingSubMenu key="advanced-standing-component" enrollment={enrollment} />,

    featureToggles.authenticatedUser &&
      featureToggles.importSubjects &&
      !readOnlyPlan &&
      !viewOnlyPlan &&
      profile &&
      !profile.isStaff &&
      profile.authenticated && (
        <MenuItem onClick={flipImportSubjectsDialog} key="add-passed-subjects-menu-item">
          <ListItemIcon style={{ color: brandGrey.toHexString() }}>
            <Icon component={AddSubjectsIcon} />
          </ListItemIcon>
          <ListItemText primary="Add enrolled/passed subjects" />
        </MenuItem>
      ),

    featureToggles.refreshButton && !readOnlyPlan && !viewOnlyPlan && (
      <MenuItem onClick={flipClearAllTemplateDialog} key="clear-plan-menu-item">
        <ListItemIcon>
          <DeleteOutlineIcon />
        </ListItemIcon>
        <ListItemText primary="Clear plan" />
      </MenuItem>
    ),

    <MenuItem onClick={openDialog} key="share-plan-menu-item">
      <ListItemIcon>
        <ShareIcon />
      </ListItemIcon>
      <ListItemText primary="Share plan" />
    </MenuItem>,

    featureToggles.duplicatePlan && !readOnlyPlan && (
      <MenuItem onClick={flipDuplicateTemplateDialog} key="duplicate-plan">
        <ListItemIcon>
          <Icon component={DuplicatePlanIcon} />
        </ListItemIcon>
        <ListItemText primary="Duplicate plan" />
      </MenuItem>
    ),

    featureToggles.subjectPlaceholders &&
      !readOnlyPlan &&
      !viewOnlyPlan &&
      isUndergrad &&
      userMode !== UserMode.ADMIN && (
        <MenuItem onClick={flipPlaceholders} key="show-hide-placeholders-on-plan">
          <ListItemIcon>
            <VisibilityOutlinedIcon />
          </ListItemIcon>
          <ListItemText primary={`${plan.showPlaceholders ? "Hide" : "Show"} Discipline/Breadth placeholders`} />
        </MenuItem>
      ),

    <MenuItem onClick={handleOpenCourse} key="view-course-details-menu-item" style={{ paddingRight: "14px" }}>
      <ListItemIcon>
        <Icon component={ViewCourseIcon} />
      </ListItemIcon>
      <ListItemText primary="View course handbook" />
    </MenuItem>,

    !readOnlyPlan && !featureToggles.authenticatedUser && (
      <MenuItem onClick={doResetHints} key="bring-back-hints-menu-item">
        <ListItemIcon>
          <LiveHelpIcon />
        </ListItemIcon>
        <ListItemText primary="Bring back dismissed hints" />
      </MenuItem>
    ),

    <MenuItem onClick={doPrint} key="print-plan">
      <ListItemIcon>
        <PrintIcon />
      </ListItemIcon>
      <ListItemText primary="Print" />
    </MenuItem>,

    !readOnlyPlan && userMode === UserMode.ADMIN && adminPath && (
      <MenuItem onClick={flipDeleteTemplateDialog} key="delete-template-menu-item">
        <ListItemIcon style={{ color: brandGrey.toHexString() }}>
          <DeleteIcon />
        </ListItemIcon>
        <ListItemText primary="Delete Template" />
      </MenuItem>
    ),
  ].filter(Boolean);

  return (
    <>
      {!!featureToggles.sampleTemplate && !plan.template && !enrollment.plan?.sourceTemplateId && (
        <SampleCoursePlanDialog open={sampleCoursePlanOpen} close={flipSampleCoursePlan} mmsRecordIds={mmsRecordIds} />
      )}
      <AutoAssignCoreDialog open={!!autoAssignRecordIds} onAccept={doAutoAssign} onCancel={closeAutoAssignModal} />
      <ChangeNameDialog
        open={changeNameDialogOpen}
        close={flipChangeNameDialog}
        plan={plan}
        isTemplate={!!plan.template}
      />
      <EditNotesDialog
        open={editNotesDialogOpen}
        close={flipEditNotesDialog}
        plan={plan}
        isTemplate={!!plan.template}
      />
      <DeleteTemplateDialog open={deleteTemplateDialogOpen} close={flipDeleteTemplateDialog} />
      <AutoAssignmentSummaryDialog
        open={!!autoAssignmentResult}
        result={autoAssignmentResult}
        onConfirm={closeAutoAssignmentSummaryModal}
      />
      <ClearAllTemplateDialog open={clearAllTemplateDialogOpen} close={flipClearAllTemplateDialog} />
      <DuplicateTemplateDialog
        focusOnNewPlan={true}
        plan={plan}
        open={duplicateTemplateDialogOpen}
        close={flipDuplicateTemplateDialog}
      />
      <ImportSubjectsDialog plan={plan} open={importSubjectsDialogOpen} close={flipImportSubjectsDialog} />
      <ControlledMenu
        className="do-not-print"
        {...menuProps}
        anchorRef={anchorRef}
        anchorPoint={anchorPoint}
        onClose={closeMenu}
      >
        {menuItems}
      </ControlledMenu>
    </>
  );
};
