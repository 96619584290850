import React, { useCallback, useEffect, useState } from "react";
import { ResponsiveCenter } from "components/spinner/Center";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  Theme,
  useTheme,
  TableBody,
  IconButton,
  TableFooter,
  TablePagination,
} from "@mui/material";
import Spinner from "components/spinner";
import { get } from "lib/fetch";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import TablePaginationActions from "@mui/material/TablePagination/TablePaginationActions";
import { formatTimestamp } from "lib/dateUtils";
import { PlanActionMenu } from "./PlanActionMenu";
import { useMenuState } from "@szhsin/react-menu";
import { ShareLinkModal } from "containers/ShareLinkModal";
import { ComponentType, HintToolTip, IPlan, IState } from "store/types";
import { useSelector } from "react-redux";
import { DuplicateTemplateDialog } from "containers/planAction/DuplicateTemplateDialog";
import { DeletePlanDialog } from "./DeletePlanDialog";
import { useNavigate } from "@reach/router";
import SortIcon from "@mui/icons-material/Sort";
import { Aligned } from "components/aligned/alignedDiv";
import { Hint } from "containers/Hint";

interface IProps {
  refresh: boolean;
}

const useTableStyle = (theme: Theme) => ({
  table: {
    marginTop: "2rem",
  },
  header: {
    "& strong": {
      fontSize: "110%",
    },
    whiteSpace: "nowrap",
  },
  span: {
    fontSize: "14px",
  },
  navButton: {
    color: theme.palette.common.black,
    marginTop: "24px",
  },
  navIcon: {
    fill: theme.palette.common.black,
  },
  templateButton: {
    color: theme.palette.common.white,
    fill: theme.palette.common.white,
  },
});

export const PlanList = ({ refresh }: IProps) => {
  const planConfig = useSelector((s: IState) => s.planCreationConfig);
  const [data, setData] = useState<any>({ plans: [], totalCount: 0 });
  const [isLoading, setLoading] = useState(false);
  const [pageIndex, gotoPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const navigate = useNavigate();

  const theme = useTheme();
  const tableStyles = useTableStyle(theme);

  // Plan action menu
  const [planId, setPlanId] = useState<string | null>(null);
  const [anchorEl, setAnchorEl] = useState<any>(null);
  const [menuProps, toggleMenu] = useMenuState({ transition: true });
  const openMenu = useCallback(() => toggleMenu(true), [toggleMenu]);
  const closeMenu = useCallback(() => toggleMenu(false), [toggleMenu]);

  const [showDialog, setShowDialog] = useState<number>(0);
  const [reloadPlans, setReloadPlans] = useState(false);

  // sorting
  const [sortBy, setSortBy] = useState("lastUpdatedTS");
  const [sortOrder, setSortOrder] = useState("desc");
  const onSort = useCallback(
    (fieldName: string) => {
      if (sortBy === fieldName) {
        // table already sorted, just change the order
        setSortOrder(sortOrder === "asc" ? "desc" : "asc");
      } else {
        // default new sort to descending
        setSortOrder("desc");
      }
      setSortBy(fieldName);
    },
    [sortBy, sortOrder],
  );
  const isTableSortedBy = (fieldName: string) => fieldName === sortBy;

  useEffect(() => {
    const load = async () => {
      try {
        setLoading(true);
        const resp = await get(
          `/v1/account/plans?pageSize=${pageSize}&pageIndex=${pageIndex}&sortBy=${sortBy}&sortOrder=${sortOrder}`,
        );
        setData(resp);
      } catch (err) {
        setData({ plans: [], totalCount: 0 });
      } finally {
        setLoading(false);
        setReloadPlans(false);
      }
    };
    load();
  }, [pageIndex, pageSize, reloadPlans, refresh, sortBy, sortOrder]);

  const handleChangePage = useCallback((_e: any, newPage: number) => gotoPage(newPage), [gotoPage]);
  const handleChangeRowsPerPage = useCallback((e: any) => setPageSize(Number(e.target.value)), [setPageSize]);

  const openActionMenu = useCallback(
    (event: any, planId: string) => {
      event.stopPropagation();
      setPlanId(planId);
      setAnchorEl(event.currentTarget);
      openMenu();
    },
    [openMenu],
  );
  const closeActionMenu = useCallback(() => {
    setAnchorEl(null);
    setPlanId(null);
    closeMenu();
  }, [closeMenu]);

  const closeAll = useCallback(() => {
    if (showDialog >= 2) {
      setReloadPlans(true);
    }
    setShowDialog(0);
    closeActionMenu();
  }, [closeActionMenu, showDialog]);

  const showDialogAction = useCallback(
    (index: number) => {
      setAnchorEl(null);
      closeMenu();
      setShowDialog(index);
    },
    [closeMenu],
  );

  const getMMS = (type: ComponentType, codes: string[], year: number) => {
    const data = planConfig?.allComponents?.filter(
      (c: any) => codes.includes(c.recordId) && c.type === type && c.publishedYears.includes(year),
    );
    return data?.map((d: any) => d.name).join(" / ");
  };

  const getDiploma = (courses: any[], year: number) => {
    if (courses.length > 0) {
      const data = planConfig?.courses?.filter(
        (c: any) => courses[0].courseRecordId === c.recordId && c.allowedStartYears.includes(year),
      );
      return data ? data[0].name : "";
    }
    return "";
  };

  const getCourseName = (recordId: string, year: number) => {
    const data = planConfig?.courses?.filter((c: any) => recordId === c.recordId && c.allowedStartYears.includes(year));
    return data && data.length === 1 ? (data[0] as any).name : "";
  };

  const getPlanLink = (planId: string | null) => {
    const plan = data.plans.find((p: IPlan) => p.id === planId);
    if (plan) {
      const course = planConfig?.courses?.find(
        (c: any) => c.recordId === plan.courseRecordId && c.allowedStartYears.includes(plan.year),
      );
      if (course) {
        return `/${course?.code}/${plan.year}/plan/${planId}`;
      }
    }
    return `/plan/${planId}`;
  };

  const editPlan = useCallback(
    (planLink: string) => {
      navigate(planLink);
    },
    [navigate],
  );

  return (
    <ResponsiveCenter>
      <Spinner loading={isLoading} />
      {!isLoading && (
        <Table sx={tableStyles.table} size="small">
          <colgroup>
            <col style={{ width: "18%" }} />
            <col style={{ width: "18%" }} />
            <col style={{ width: "12%" }} />
            <col style={{ width: "18%" }} />
            <col style={{ width: "18%" }} />
            <col style={{ width: "6%" }} />
            <col style={{ width: "6%" }} />
            <col style={{ width: "4%" }} />
          </colgroup>
          <Hint hintType={HintToolTip.ACCOUNT_PLAN_LIST} placement="top">
            <TableHead>
              <TableRow sx={tableStyles.header}>
                <TableCell style={{ cursor: "pointer" }} onClick={() => onSort("name")}>
                  <Aligned>
                    <strong>Plan Name</strong>
                    &nbsp;
                    {isTableSortedBy("name") && (
                      <SortIcon style={{ ...(sortOrder === "asc" && { transform: "rotate(180deg)" }) }} />
                    )}
                  </Aligned>
                </TableCell>
                <TableCell>
                  <strong>Course</strong>
                </TableCell>
                <TableCell>
                  <strong>Commencement</strong>
                </TableCell>
                <TableCell>
                  <strong>Major</strong>
                </TableCell>
                <TableCell>
                  <strong>Diploma</strong>
                </TableCell>
                <TableCell style={{ cursor: "pointer" }} onClick={() => onSort("progress")}>
                  <Aligned>
                    <strong>% Planned</strong>
                    {isTableSortedBy("progress") && (
                      <SortIcon style={{ ...(sortOrder === "asc" && { transform: "rotate(180deg)" }) }} />
                    )}
                  </Aligned>
                </TableCell>
                <TableCell style={{ cursor: "pointer" }} onClick={() => onSort("lastUpdatedTS")}>
                  <Aligned>
                    <strong>Last Updated</strong>
                    {isTableSortedBy("lastUpdatedTS") && (
                      <SortIcon style={{ ...(sortOrder === "asc" && { transform: "rotate(180deg)" }) }} />
                    )}
                  </Aligned>
                </TableCell>
                <TableCell>
                  <strong>Actions</strong>
                </TableCell>
              </TableRow>
            </TableHead>
          </Hint>
          <TableBody>
            {data.plans.length === 0 && (
              <TableRow>
                <TableCell colSpan={7}>
                  <p style={{ textAlign: "center" }}>No plans</p>
                </TableCell>
              </TableRow>
            )}
            {data.plans.length > 0 &&
              data.plans.map((plan: any, i: number) => (
                <TableRow key={`row_${i}`} onClick={() => editPlan(getPlanLink(plan.id))} style={{ cursor: "pointer" }}>
                  <TableCell>{plan.name}</TableCell>
                  <TableCell>{getCourseName(plan.courseRecordId, plan.year)}</TableCell>
                  <TableCell>
                    {plan.year}, {plan.isMidyearEntry ? "mid year" : "start year"}
                  </TableCell>
                  <TableCell>{getMMS(ComponentType.MAJOR, plan.mmsRecordIds, plan.year)}</TableCell>
                  <TableCell>{getDiploma(plan.secondaryCourses, plan.year)}</TableCell>
                  <TableCell style={{ textAlign: "center" }}>{plan.progress}</TableCell>
                  <TableCell>{formatTimestamp(plan.lastUpdatedTS!)}</TableCell>
                  <TableCell>
                    <IconButton onClick={(e: any) => openActionMenu(e, plan.id)}>
                      <MoreVertIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[10, 20, 30, 50, 100]}
                count={data.totalCount}
                rowsPerPage={pageSize}
                page={pageIndex}
                SelectProps={{
                  inputProps: { "aria-label": "rows per page" },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      )}
      <PlanActionMenu
        planLink={getPlanLink(planId)}
        anchorEl={anchorEl}
        isOpen={anchorEl !== null}
        closeMenu={closeActionMenu}
        shareDialog={() => showDialogAction(1)}
        duplicateDialog={() => showDialogAction(2)}
        deleteDialog={() => showDialogAction(3)}
        {...menuProps}
      />
      {showDialog === 1 && (
        <ShareLinkModal
          open={showDialog === 1}
          handleClose={() => closeAll()}
          thePlan={data.plans.find((p: IPlan) => p.id === planId)}
          link={`${window.location.origin}${getPlanLink(planId)}`}
        />
      )}
      {showDialog === 2 && (
        <DuplicateTemplateDialog
          plan={data.plans.find((p: IPlan) => p.id === planId)}
          open={showDialog === 2}
          focusOnNewPlan={false}
          close={() => closeAll()}
        />
      )}
      {showDialog === 3 && (
        <DeletePlanDialog
          plan={data.plans.find((p: IPlan) => p.id === planId)}
          open={showDialog === 3}
          close={() => closeAll()}
        />
      )}
    </ResponsiveCenter>
  );
};
