import React from "react";
import { Typography, Dialog, DialogContent, IconButton, useTheme } from "@mui/material";
import MuiDialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import { brandBlue, fadedBrandBlue1, fadedBrandBlue2 } from "theme/colors";
import { Theme } from "@mui/material/styles";

const useStyles = (theme: Theme) => ({
  input: {
    paddingRight: "0px",
    backgroundColor: theme.palette.background.paper,
    color: brandBlue.toString(),
  },
  button: {
    alignSelf: "stretch",
    backgroundColor: fadedBrandBlue2.toString(),
    color: "white",
    fontWeight: "bold",
    borderRadius: "0px 4px 4px 0px",
    padding: "12px 24px",
    "&:hover": {
      backgroundColor: fadedBrandBlue1.toString(),
    },
  },
  dialogHeader: {
    display: "flex",
    justifyContent: "space-between",
    padding: "16px 24px 0px 24px",
  },
  dialogTitle: {
    alignSelf: "center",
  },
  dialogContent: {
    padding: "0px 24px 24px 24px",
  },
  typographyGutterBottom: {
    marginBottom: "1.5em",
  },
  helperText: {
    marginLeft: 0,
  },
});

interface IProps {
  handleClose: () => any;
}

export const ROAccessModal = ({ handleClose }: IProps) => {
  const theme = useTheme();
  const styles = useStyles(theme);
  const handleCloseModal = () => {
    handleClose();
  };

  const DialogTitle = ({ children, onClose, ...other }: any) => (
    <MuiDialogTitle sx={styles.dialogHeader} disableTypography {...other}>
      <Typography sx={styles.dialogTitle} variant="h6">
        {children}
      </Typography>
      {onClose ? (
        <IconButton aria-label="close" onClick={onClose} size="large">
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );

  return (
    <Dialog
      open={true}
      maxWidth="md"
      fullWidth={true}
      onClose={handleCloseModal}
      aria-labelledby="readonly-plan-dialog-title"
    >
      <DialogTitle id="readonly-plan-dialog-title" onClose={handleCloseModal}>
        Limited Access to Plan
      </DialogTitle>
      <DialogContent sx={styles.dialogContent}>
        <Typography
          variant="subtitle1"
          gutterBottom
          id="readonly-plan-label"
          color="textSecondary"
          sx={{ gutterBottom: styles.typographyGutterBottom }}
        >
          Your access to this plan is view-only.
          <br />
          As a guest user, you can duplicate this plan to save it as your own.
          <br />
          Alternatively, login to My Course Planner and import it into your account to fully manage this plan.
        </Typography>
      </DialogContent>
    </Dialog>
  );
};
