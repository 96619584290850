import { HintToolTip, IEnrollment, Semester } from "../../../store/types";
import { MenuItem } from "@szhsin/react-menu";
import React, { useCallback, useMemo, useState } from "react";
import ListItemIcon from "@mui/material/ListItemIcon";
import { brandGrey } from "../../../theme/colors";
import ListItemText from "@mui/material/ListItemText";
import { usePlanPermissions } from "../../../lib/usePlanPermissions";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
import { Hint } from "../../Hint";
import { flipStudyPeriod } from "../../../actions/enrollment";
import { useDispatch } from "react-redux";

interface IProps {
  enrollment: IEnrollment;
}

export const AdvancedStandingSubMenu = (props: IProps) => {
  const { enrollment } = props;
  const { plan } = enrollment;

  const { id: planId, year: planYear } = plan;

  const dispatch = useDispatch();

  const { readOnlyPlan, viewOnlyPlan } = usePlanPermissions();
  const hasAdvancedStanding = useMemo(
    () => !!plan.studyPeriods.find((sp) => sp.type === Semester.ADVANCED_STANDING),
    [plan.studyPeriods],
  );
  const [isShownHoverContent, setIsShownHoverContent] = useState(false);

  const doFlipAdvancedStanding = useCallback(
    (_e: React.MouseEvent) => {
      return planId && dispatch(flipStudyPeriod(planId, planYear, Semester.ADVANCED_STANDING));
    },
    [dispatch, planId, planYear],
  );

  return (
    <>
      {!readOnlyPlan && !viewOnlyPlan ? (
        hasAdvancedStanding ? (
          <MenuItem onClick={doFlipAdvancedStanding} key="remove-advanced-standing-menu-item">
            <ListItemIcon style={{ color: brandGrey.toHexString() }}>
              <RemoveCircleOutlineIcon />
            </ListItemIcon>
            <ListItemText primary="Remove advanced standing" />
          </MenuItem>
        ) : (
          <MenuItem
            onMouseOver={() => setIsShownHoverContent(true)}
            onMouseLeave={() => setIsShownHoverContent(false)}
            onClick={doFlipAdvancedStanding}
            key="add-advanced-standing-menu-item"
          >
            <ListItemIcon style={{ color: brandGrey.toHexString() }}>
              <PlaylistAddIcon />
            </ListItemIcon>
            <Hint
              hintType={HintToolTip.ADD_CREDIT_ADVANCED_STANDING}
              hideHint={!isShownHoverContent}
              placement="right"
              disableClose={true}
            >
              <ListItemText primary="Add advanced standing" />
            </Hint>
          </MenuItem>
        )
      ) : null}
    </>
  );
};
