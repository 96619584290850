import { FormControl } from "@mui/material";
import { Autocomplete } from "@mui/material";
import { IComponent, ICourseSummary, QualificationLevel } from "../../store/types";
import { Field, useFormikContext } from "formik";
import React from "react";
import { TextField } from "formik-mui";
import InputLabel from "@mui/material/InputLabel/InputLabel";

interface IProps {
  index: number;
  courses: any;
  components: any;
}

export const ValueGroup = (props: IProps) => {
  const { values, setFieldValue } = useFormikContext<any>(); // formikProps
  const index = props.index;
  const courses = props.courses;
  const components = props.components;

  return (
    <div>
      <InputLabel
        aria-label="Select course"
        htmlFor={`course-select-${index}`}
        id={`course-select-label-${index}`}
        required={false}
        style={{ marginTop: "12px", marginBottom: "12px" }}
      >
        Select course (if applicable)
      </InputLabel>
      <FormControl fullWidth variant="outlined">
        <Autocomplete
          id={`course-select-${index}`}
          options={courses.filter((c: any) => {
            if (
              // values.courseKey?.qualificationLevel == QualificationLevel.DIPLOMA ||
              // values.displayKeyComp?.course?.qualificationLevel == QualificationLevel.DIPLOMA
              values.courseKey?.isConcurrentCourse == "true" ||
              values.displayKeyComp?.course?.isConcurrentCourse == "true"
            ) {
              // return c.qualificationType[0]?.aqfLevel !== QualificationLevel.DIPLOMA;
              return c.isConcurrentCourse !== "true";
            } else {
              // return c.qualificationType[0]?.aqfLevel == QualificationLevel.DIPLOMA;
              return c.isConcurrentCourse == "true";
            }
          })}
          getOptionLabel={(option: ICourseSummary) => `${option.name} (${option.code})`}
          style={{ width: "100%" }}
          renderInput={(params: any) => (
            <Field component={TextField} {...params} name={`disAllowedComp.${index}.course`} variant="outlined" />
          )}
          value={courses.find((c: any) => {
            return c.code === values.disAllowedComp[index].course?.code ?? null;
          })}
          onChange={(_e: any, course: ICourseSummary | null) => {
            setFieldValue(
              `disAllowedComp.${index}.course`,
              // eslint-disable-next-line max-len
              {
                name: course?.name, code: course?.code, qualificationLevel: course?.qualificationType[0]?.aqfLevel,
                isConcurrentCourse: course?.isConcurrentCourse
              } ??
              null,
            );
          }}
        />
      </FormControl>
      {/* {(values.courseKey?.qualificationLevel == QualificationLevel.DIPLOMA ||
        values.displayKeyComp?.course?.qualificationLevel == QualificationLevel.DIPLOMA) && ( */}
      {(values.courseKey?.isConcurrentCourse == "true" ||
        values.displayKeyComp?.course?.isConcurrentCourse == "true") && (
          <>
            <InputLabel
              aria-label="Select mms"
              htmlFor={`mms-select-${index}`}
              id={`mms-select-label-${index}`}
              required={false}
              style={{ marginTop: "12px", marginBottom: "12px" }}
            >
              Select mms (if applicable)
            </InputLabel>
            <FormControl fullWidth variant="outlined">
              <Autocomplete
                id={`mms-select-${index}`}
                options={components.filter(
                  (c: IComponent) =>
                    c.parentCourse.code === values.disAllowedComp[index].course?.code &&
                    c.publishedYears == new Date().getFullYear().toString(),
                )}
                getOptionLabel={(option: ICourseSummary) => `${option.name} (${option.code})`}
                style={{ width: "100%" }}
                renderInput={(params: any) => (
                  <Field component={TextField} {...params} name={`disAllowedComp.${index}.mms`} variant="outlined" />
                )}
                value={components.find((c: any) => {
                  return c.code === values.disAllowedComp[index].mms?.code ?? null;
                })}
                onChange={(_e: any, mms: any | null) => {
                  setFieldValue(`disAllowedComp.${index}.mms`, { name: mms?.name, code: mms?.code } ?? null);
                }}
              />
            </FormControl>
          </>
        )}
    </div>
  );
};
