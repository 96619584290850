import React, { useCallback } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { useDispatch } from "react-redux";
import { openSnackBarWithError } from "../../actions/snackBar";
import { setSubmittingChanges, START_SUBMITTING, STOP_SUBMITTING } from "../../actions/submittingChanges";
import { FormControl, Theme, Typography, useTheme } from "@mui/material";
import { TextField } from "formik-mui";
import { Formik, Field, FormikHelpers } from "formik";
import { validateValuePresence } from "lib/validation";
import { isEmpty } from "lodash";
import { post } from "lib/fetch";
import { brandBlue } from "theme/colors";
import MuiDialogTitle from "@mui/material/DialogTitle";

interface IProps {
  open: boolean;
  refresh: () => any;
  close: () => any;
}

export interface IDuplicateNameFormVals {
  name: string;
}

const useStyles = (theme: Theme) => ({
  input: {
    paddingRight: "0px",
    backgroundColor: theme.palette.background.paper,
    color: brandBlue.toString(),
  },
  dialogHeader: {
    display: "flex",
    justifyContent: "space-between",
    padding: "16px 24px 0px 24px",
  },
  dialogTitle: {
    alignSelf: "center",
  },
  dialogContent: {
    padding: "0px 24px 0px 24px",
  },
  typographyGutterBottom: {
    marginBottom: "1.5em",
  },
});

export const ImportPlanDialog = ({ open, refresh, close }: IProps) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const styles = useStyles(theme);

  const DialogTitle = ({ children, ...other }: any) => (
    <MuiDialogTitle sx={styles.dialogHeader} disableTypography {...other}>
      <Typography sx={styles.dialogTitle} variant="h6">
        {children}
      </Typography>
    </MuiDialogTitle>
  );

  const initialValues: IDuplicateNameFormVals = { name: "" };

  const onSubmit = useCallback(
    async (values: IDuplicateNameFormVals, helpers: FormikHelpers<IDuplicateNameFormVals>) => {
      close();

      try {
        dispatch(setSubmittingChanges(START_SUBMITTING));
        const regex = /\/([^/]+)\/?$/;
        const match = values.name.match(regex);
        if (match) {
          const payload = await post(`/v1/enrollment/${match[1]}/duplicatePlan`, {});
          if (payload && payload.planId) {
            refresh();
          }
        }
      } catch (_e) {
        dispatch(openSnackBarWithError(`Could not import plan`));
      } finally {
        dispatch(setSubmittingChanges(STOP_SUBMITTING));
        helpers.setSubmitting(false);
      }
    },
    [close, dispatch, refresh],
  );

  const validateFormState = useCallback((values: IDuplicateNameFormVals) => {
    const errors: any = {};
    validateValuePresence(errors, values, "name");
    return errors;
  }, []);

  if (!open) {
    return null;
  }

  return (
    <Formik initialValues={initialValues} validate={validateFormState} onSubmit={onSubmit}>
      {(props) => (
        <Dialog
          open={open}
          onClose={close}
          aria-labelledby="import-plan-dialog-title"
          aria-describedby="import-plan-dialog-description"
          maxWidth="sm"
          fullWidth
        >
          <DialogTitle id="import-plan-dialog-title" onClose={close}>
            Import existing plan
          </DialogTitle>
          <DialogContent sx={styles.dialogContent}>
            <Typography
              variant="subtitle1"
              gutterBottom
              id="import-plan-link-input-label"
              color="textSecondary"
              sx={{ gutterBottom: styles.typographyGutterBottom }}
            >
              Add the link from your existing plan
            </Typography>
            <form onSubmit={props.handleSubmit} id="import-plan-form">
              <FormControl fullWidth variant="outlined" required>
                <Field
                  // style={{ width: `min(80vw, 400px)` }}
                  component={TextField}
                  sx={{ margin: "4px 1px" }}
                  name="name"
                  fullWidth={true}
                  variant="outlined"
                />
              </FormControl>
              <p style={{ fontSize: 12, lineHeight: "12px" }}>This link is the browser URL.</p>
            </form>
          </DialogContent>
          <DialogActions>
            <Button onClick={close} variant="contained">
              Cancel
            </Button>
            <Button
              type="submit"
              form="import-plan-form"
              color="primary"
              variant="contained"
              autoFocus
              disabled={props.isSubmitting || !props.dirty || !isEmpty(props.errors)}
            >
              Import
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Formik>
  );
};
