import React, { useCallback, useRef } from "react";
import {
  IState,
  ISlotIdentifier,
  HintToolTip,
  ISlot,
  Semester,
  SubjectFocusState,
  ISubject,
  IRuleInvocation,
  EnrollmentStatus,
} from "store/types";
import { Card, CardHeader, IconButton, CardContent, Chip, useTheme, Tooltip, Icon } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import uniq from "lodash/uniq";
import { useDispatch, useSelector } from "react-redux";
import { cardStyles, CardSubheading, CardTitle } from "./cardStyles";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import { Aligned } from "components/aligned/alignedDiv";
import { useLocation, useNavigate } from "@reach/router";
import {
  getSubjectAssignmentLabel,
  analyseSubject,
  getSubject,
  getAllKnownSubjects,
  getSubjectImportType,
} from "lib/subjects";
import isArray from "lodash/isArray";
import { subjectHasAvailabilityErrors, subjectHasErrors } from "lib/validation";
import { openSubjectChecklist } from "actions/checklistDrawerForSubjectId";
import { getSubjectRecordIds, isFirstSubjectInPlan } from "lib/plan";
import { Hint } from "containers/Hint";
import { useMenuState } from "@szhsin/react-menu";
import { SubjectMenu } from "./menuItems/subjectMenu";
import { usePlanPermissions } from "lib/usePlanPermissions";
import { trimEnd } from "lodash";
import "@szhsin/react-menu/dist/index.css";
import { SlotCardHeaderTitle } from "./slotCardHeaderTitle";
import { featureToggles } from "config/featureToggles";
import { ReactComponent as SubjectTickIcon } from "./SubjectTickIcon.svg";

export interface IProps extends ISlotIdentifier {
  isDragging: boolean;
  displaySlot: ISlot;
}

const getSubjectFocusedState = (focusInvocation: IRuleInvocation | undefined, subject: ISubject): SubjectFocusState => {
  const subjectFocused = (focusInvocation?.matchingDetails?.recordIds ?? []).indexOf(subject?.recordId ?? "") >= 0;
  let subjectFocusState = SubjectFocusState.NO_HIGHLIGHT;
  if (!!focusInvocation) {
    subjectFocusState = subjectFocused ? SubjectFocusState.HIGHLIGHTED : SubjectFocusState.DIMMED;
    if (focusInvocation?.recordId === subject.recordId) {
      subjectFocusState = SubjectFocusState.TARGET;
    }
  }
  return subjectFocusState;
};

const computeElevation = (focusState: SubjectFocusState) => {
  switch (focusState) {
    case SubjectFocusState.DIMMED:
      return 0;
    case SubjectFocusState.HIGHLIGHTED:
    case SubjectFocusState.TARGET:
      return 5;
    case SubjectFocusState.NO_HIGHLIGHT:
    default:
      return 2;
  }
};

export const getSubjectImportTypeColor = (type: string) => {
  switch (type.toLowerCase()) {
    case EnrollmentStatus.Credited:
      return "#34495E";
    case EnrollmentStatus.Enrolled:
      return "#D81B60";
    case EnrollmentStatus.Exempt:
      return "#9B59B6";
    case "Passed":
    default:
      return "#1C407F";
  }
};

export const SubjectSlotCard = (props: IProps) => {
  const plan = useSelector((state: IState) => state.enrollment!.plan);
  const allSubjects = useSelector((s: IState) => getAllKnownSubjects(s));

  const { year, semester, slotIndex, isDragging, displaySlot } = props;

  const subject = getSubject(allSubjects, displaySlot?.subjectRecordId)!;

  const subjectAvailabilities = isArray(subject.availability) ? subject.availability : [subject.availability];
  const availabilities = uniq(subjectAvailabilities.map((a) => a?.studyPeriod)).filter(Boolean);

  const components = useSelector((s: IState) => s.enrollment?.allowedComponents || []);
  const assignedComponent = components.find((c) => c.recordId === displaySlot?.mmsRecordId);

  const allowedSecondaryCourses = useSelector((s: IState) => s.enrollment?.allowedSecondaryCourses || []);
  const assignedSecondaryCourse = allowedSecondaryCourses.find(
    (c) => (displaySlot?.courseRecordIds ?? []).indexOf(c.recordId) >= 0,
  );

  const invocations = useSelector((s: IState) => s.enrollment?.validation.invocations || []);
  const groups = useSelector((s: IState) => s.enrollment?.validation.groups || []);

  // TODO advanced standing should get no errors in the engine - remove this condition once done
  const showErrors = semester !== Semester.ADVANCED_STANDING && subjectHasErrors(invocations, groups, subject.recordId);

  const focusInvocationId = useSelector((s: IState) => (subject ? s.focusInvocationId : null));
  const focusInvocation = invocations.find((i) => i.ruleId === focusInvocationId);
  const focusState = getSubjectFocusedState(focusInvocation, subject);

  const subjectImportType = getSubjectImportType(displaySlot);

  const hasPrequisites =
    subjectHasErrors(invocations, groups, subject.recordId, "Prerequisites") ||
    subjectHasErrors(invocations, groups, subject.recordId, "Corequisites") ||
    subjectHasErrors(invocations, groups, subject.recordId, "Non-allowed subjects");
  const { hasAvailableInOtherPeriod, hasNotAvailableInCurrentYear } = subjectHasAvailabilityErrors(
    invocations,
    subject.recordId,
  );
  const elevation = computeElevation(focusState);

  const subjectMeta = analyseSubject(subject);
  const subjectTag = subjectMeta.uiTag;
  const theme = useTheme();
  const styles = cardStyles(theme, {
    tag: subjectTag,
    subjectFocusState: focusState,
    disabled: focusState === SubjectFocusState.DIMMED || !!displaySlot?.disabled,
  });

  // Menu config
  const ref = useRef(null);
  const [menuProps, toggleMenu] = useMenuState({ transition: true });
  const openMenu = useCallback(() => toggleMenu(true), [toggleMenu]);
  const closeMenu = useCallback(() => toggleMenu(false), [toggleMenu]);

  const subjectRecordId = subject?.recordId;
  const btnId = `settings-menu-${year}-${semester}-${slotIndex}`.replace(/\s/g, "");
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const openDetailsModal = useCallback(
    () => navigate(`${trimEnd(pathname, "/")}/subject/${subjectRecordId}`),
    [navigate, pathname, subjectRecordId],
  );

  // Hints
  const isFirst = isFirstSubjectInPlan(plan, props);
  const subjectRecordIds = getSubjectRecordIds(plan);
  const isFirstFailed =
    subject.recordId === subjectRecordIds.find((recordId) => subjectHasErrors(invocations, groups, recordId));

  const dispatch = useDispatch();
  const openDrawer = useCallback(() => {
    dispatch(openSubjectChecklist(subjectRecordId));
  }, [dispatch, subjectRecordId]);

  const title = subjectTag ? getSubjectAssignmentLabel(subjectTag, assignedSecondaryCourse ?? assignedComponent) : "";

  const { readOnlyPlan } = usePlanPermissions();

  return (
    <Card sx={styles.card} elevation={elevation}>
      <CardHeader
        color="primary"
        action={
          displaySlot?.disabled || readOnlyPlan ? undefined : (
            <>
              <Hint
                hintType={HintToolTip.SUBJECT_DETAILS}
                hideHint={!isFirst || isDragging || menuProps.state === "open"}
                disablePortal
              >
                <IconButton
                  ref={ref}
                  className="do-not-print"
                  aria-label="Subject menu"
                  // aria-controls={btnId}
                  onClick={openMenu}
                  size="large"
                >
                  <MoreVertIcon sx={styles.icon} />
                </IconButton>
              </Hint>
              <SubjectMenu
                {...props}
                {...menuProps}
                htmlId={btnId}
                anchorRef={ref}
                openDetailsModal={openDetailsModal}
                close={closeMenu}
                subjectRecordId={subject.recordId}
                subject={subject}
                allowMove={subjectImportType === null}
              />
            </>
          )
        }
        title={<SlotCardHeaderTitle title={title} subjectMeta={subjectMeta} />}
        titleTypographyProps={{ variant: "button" }}
        subheaderTypographyProps={{ variant: "inherit" }}
        sx={styles.cardHeader}
      />
      <CardContent sx={styles.cardContent}>
        <CardSubheading>
          <strong>{subject.code}</strong> | Level {subject.level} | {subject.points} points
        </CardSubheading>
        <Aligned style={{ alignItems: "start", width: "100%", justifyContent: "start" }}>
          <CardTitle>{subject.name}</CardTitle>
          {showErrors && !featureToggles.subjectRuleFailureIndicators && (
            <Hint
              hintType={HintToolTip.SUBJECT_PREREQ}
              hideHint={!isFirstFailed || isDragging || menuProps.state === "open"}
              disablePortal
              placement="right"
            >
              <IconButton size="small" onClick={openDrawer} aria-label="show errors">
                <CancelRoundedIcon sx={styles.errorIcon} fontSize="small" />
              </IconButton>
            </Hint>
          )}
        </Aligned>
        {subjectImportType !== null && (
          <React.Fragment>
            <Chip
              avatar={<Icon component={SubjectTickIcon} />}
              sx={{
                backgroundColor: getSubjectImportTypeColor(subjectImportType),
                color: "white",
              }}
              label={subjectImportType}
              size="small"
            />
          </React.Fragment>
        )}
        {subjectImportType === null &&
          availabilities.map((a) => (
            <React.Fragment key={a}>
              <Chip sx={styles.cardChip} label={a} size="small" /> &nbsp;
            </React.Fragment>
          ))}
        {subjectImportType === null && featureToggles.subjectRuleFailureIndicators && (
          <div>
            {showErrors && (
              <>
                <Hint
                  hintType={HintToolTip.SUBJECT_PREREQ}
                  hideHint={!isFirstFailed || isDragging || menuProps.state === "open"}
                  disablePortal
                  placement="right"
                >
                  <IconButton size="small" onClick={openDrawer} aria-label="show errors" style={{ marginTop: "5px" }}>
                    <CancelRoundedIcon sx={styles.errorIcon} fontSize="small" />
                  </IconButton>
                </Hint>
              </>
            )}
            {hasPrequisites && (
              <>
                <Tooltip title="Review requisites for this subject.">
                  <Chip label="Requisites Apply" variant="outlined" color="error" size="small" onClick={openDrawer} />
                </Tooltip>
                &nbsp;
              </>
            )}
            {((hasNotAvailableInCurrentYear && availabilities.length > 0) || hasAvailableInOtherPeriod) && (
              <>
                <Tooltip title="Move subject to an alternate study period in the plan.">
                  <Chip
                    label="Study Period Incorrect"
                    variant="outlined"
                    color="error"
                    size="small"
                    onClick={openDrawer}
                  />
                </Tooltip>
                &nbsp;
              </>
            )}
            {hasNotAvailableInCurrentYear && availabilities.length === 0 && (
              <>
                <Tooltip title="Subject availability may change in future years.">
                  <Chip
                    label="Currently Unavailable"
                    variant="outlined"
                    color="error"
                    size="small"
                    onClick={openDrawer}
                  />
                </Tooltip>
                &nbsp;
              </>
            )}
            {!hasPrequisites && !hasAvailableInOtherPeriod && !hasNotAvailableInCurrentYear && showErrors && (
              <Tooltip title="Review requisites for this subject.">
                <Chip label="Requisites Apply" variant="outlined" color="error" size="small" onClick={openDrawer} />
              </Tooltip>
            )}
          </div>
        )}
      </CardContent>
    </Card>
  );
};
