import React, { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IState, IStudyPeriod, Semester } from "store/types";
import styled from "styled-components";
import { groupBy, max, orderBy, sum, toPairs } from "lodash";
import { sortBy } from "lodash";
import { ResponsiveCenter } from "components/spinner/Center";
import { SlotCard } from "containers/slotCard/slotCard";
import { AdvancedStandingHeader, YearHeader } from "./GridHeader";
import { ExpandedChecklist } from "./checklist/Expanded";
import { CollapsedChecklist } from "./checklist/Collapsed";
import { Aligned } from "components/aligned/alignedDiv";
import Collapse from "@mui/material/Collapse";
import { advancedStandingGrid24Pattern, getOverloadForPeriod, subjectGridRowPattern } from "lib/subjectGrid";
import { ordinalSemester } from "lib/plan";
import { IconButton, Tooltip } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { addOverload } from "actions/enrollment";
import { featureToggles } from "config/featureToggles";
import { OverloadDialog } from "./planAction/OverloadDialog";

const H3 = styled.h3`
  margin-top: 0;
  margin-bottom: 6px;
  color: #575757;
  font-size: 1rem;
`;
const GridContainer = styled.div`
  flex-grow: 1;
`;
const SlotGridRow = styled.div`
  margin: -1rem 0 1.5rem;
  display: grid;
  grid-row-gap: 12px;
`;
const CardContainer = styled.div`
  padding-right: 6px;
`;

export const CourseGrid = () => {
  const plan = useSelector((state: IState) => state.enrollment!.plan);
  const overrides = useSelector((state: IState) => state.enrollment!.overloads ?? []);
  const course = useSelector((state: IState) => state.enrollment!.course);

  const optimisticUpdate = useSelector((state: IState) => state.optimisticUpdate);
  const [checkListOpen, setCheckListOpen] = useState(true);
  const [collapsedYears, setCollapsedYears] = useState<Record<string, boolean>>({});
  const flipCollapsedYear = useCallback(
    (year: number | string) => setCollapsedYears((old) => ({ ...old, [year]: !old[String(year)] })),
    [],
  );
  const [collapsedAdvancedStanding, setCollapsedAdvancedStanding] = useState(false);
  const flipCollapsedAdvancedStanding = useCallback(() => setCollapsedAdvancedStanding((old) => !old), []);

  const allStudyPeriods = optimisticUpdate?.plan?.studyPeriods || plan?.studyPeriods || [];
  const maxSemesterPoints = max(allStudyPeriods.map((sp) => sum(sp.slots.map((s) => s.displayPoints ?? 12.5))))!;
  const advancedStanding = allStudyPeriods.find((sp) => sp.type === Semester.ADVANCED_STANDING);
  const studyPeriods = allStudyPeriods.filter((sp) => sp.type !== Semester.ADVANCED_STANDING);
  const byYear = groupBy(studyPeriods, (sp) => sp.year);
  const yearGroupsPairs = toPairs(byYear).map((p) => [parseInt(p[0]), p[1]]);

  const allYearGroups = sortBy(yearGroupsPairs, (p) => p[0]).map((p) => p[1] as IStudyPeriod[]);
  const yearGroups = allYearGroups.map((yearGroup) => orderBy(yearGroup, (g) => ordinalSemester(g.type)));
  // yearGroups.forEach((item) => {
  //   item.sort((a, b) => ordinalSemester(a.type) - ordinalSemester(b.type));
  // });

  const [showOverloadDialog, setShowOverloadDialog] = useState(false);
  const [isFirstOverloadDialog, setIsFirstOverloadDialog] = useState(true);
  const [overloadStudyPeriod, setOverloadStudyPeriod] = useState({} as IStudyPeriod);
  const dispatch = useDispatch();
  const onAddOverload = (sp: IStudyPeriod) => {
    if (isFirstOverloadDialog) {
      setShowOverloadDialog(false);
      setIsFirstOverloadDialog(false);
    }
    dispatch(addOverload(plan.id!, sp));
  };

  const onOverloadClick = (sp: IStudyPeriod) => {
    if (isFirstOverloadDialog) {
      setShowOverloadDialog(true);
      setOverloadStudyPeriod(sp);
    } else {
      onAddOverload(sp);
    }
  };

  const flipChecklistExpansion = useCallback(() => setCheckListOpen((o) => !o), [setCheckListOpen]);

  return (
    <ResponsiveCenter>
      <OverloadDialog
        open={showOverloadDialog}
        close={() => setShowOverloadDialog(false)}
        onChangeOverload={() => onAddOverload(overloadStudyPeriod)}
      />
      <Aligned style={{ alignItems: undefined, justifyContent: "space-between", minHeight: "calc(100vh - 345px)" }}>
        <GridContainer>
          {advancedStanding && (
            <>
              <AdvancedStandingHeader
                collapsed={collapsedAdvancedStanding}
                flipCollapsed={flipCollapsedAdvancedStanding}
              />
              <Collapse className="printable-collapse" in={!collapsedAdvancedStanding}>
                {renderAdvancedStanding(advancedStanding, maxSemesterPoints)}
              </Collapse>
            </>
          )}
          {yearGroups.map((yearStudyPeriods, yi) => {
            const year = yearStudyPeriods[0].year;
            const collapsed = collapsedYears[String(year)];
            return (
              <div key={`year-${year}`}>
                <div className="print-on-single-page">
                  <YearHeader
                    yearIndex={yi}
                    year={year}
                    collapsed={!!collapsed}
                    flipCollapsedYear={flipCollapsedYear}
                    plan={plan}
                    course={course}
                  />
                  <Collapse className="printable-collapse" in={!collapsed}>
                    {renderStudyPeriod(
                      year,
                      yearStudyPeriods[0],
                      maxSemesterPoints,
                      getOverloadForPeriod(yearStudyPeriods[0], overrides),
                      onOverloadClick,
                      // Disallow overloading for first year, first semester.
                      yi === 0,
                    )}
                  </Collapse>
                </div>
                <Collapse className="printable-collapse" in={!collapsed}>
                  {yearStudyPeriods.slice(1).map((sp, i) => (
                    <div key={i} className="print-on-single-page">
                      {renderStudyPeriod(
                        year,
                        sp,
                        maxSemesterPoints,
                        getOverloadForPeriod(sp, overrides),
                        onOverloadClick,
                        // Disallow overloading for first year, second semester if the first semester was summer or winter
                        yi === 0 &&
                          i === 0 &&
                          !![Semester.SUMMER, Semester.WINTER].find((s) => s === yearStudyPeriods[0].type),
                      )}
                    </div>
                  ))}
                </Collapse>
              </div>
            );
          })}
        </GridContainer>
        <div className="do-not-print">
          {checkListOpen ? (
            <ExpandedChecklist close={flipChecklistExpansion} />
          ) : (
            <CollapsedChecklist open={flipChecklistExpansion} />
          )}
        </div>
      </Aligned>
    </ResponsiveCenter>
  );
};
const renderStudyPeriod = (
  year: number,
  sp: IStudyPeriod,
  maxSemesterPoints: number,
  overload: number,
  onOverloadClick: (sp: IStudyPeriod) => any,
  disallowOverloading = false,
) => {
  const { type: semester, slots } = sp;
  // CPT-1335 - set the max to 50 to ensure we have 4 slots on the page
  const sizes = subjectGridRowPattern(slots, semester, Math.min(maxSemesterPoints, 50));
  const gridTemplateColumns = sizes.map((s) => `${s}fr`).join(" ");

  return (
    <div key={`year-${year}-sem-${semester}`} className="print-on-single-page">
      <H3>
        <Aligned>
          {semester}
          &nbsp;
          {!disallowOverloading && featureToggles.semesterOverloads && overload < 12.5 && (
            <Tooltip arrow title="Overload semester" placement="top">
              <IconButton size="small" aria-label="overload semester" onClick={() => onOverloadClick(sp)}>
                <AddIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          )}
        </Aligned>
      </H3>

      <SlotGridRow style={{ gridTemplateColumns }}>
        {slots.map((slot) => (
          <CardContainer key={`year-${year}-sem-${semester}-slot-${slot.sequence}`}>
            <SlotCard year={year} semester={semester} slotIndex={slot.sequence} displaySlot={slot} />
          </CardContainer>
        ))}
      </SlotGridRow>
    </div>
  );
};

const renderAdvancedStanding = (sp: IStudyPeriod, maxSemesterPoints: number) => {
  const { slots } = sp;
  const gridPattern = advancedStandingGrid24Pattern(slots);

  return (
    <div key={`year-${sp.year}-sem-${sp.type}`} className="print-on-single-page">
      <SlotGridRow
        style={{
          gridTemplateColumns: `repeat( ${6 * Math.trunc(Math.min(maxSemesterPoints, 50) / 12.5)}, 1fr)`,
        }}
      >
        {gridPattern.map((gridEl, i) => {
          const { slot } = gridEl;
          const gridElStyle = {
            gridRow: gridEl.row + 1,
            gridColumn: `${gridEl.colStart + 1} / span ${gridEl.colSpan}`,
          };
          const key = `year-${sp.year}-sem-${sp.type}-gird-${i}`;

          return slot ? (
            <CardContainer key={key} style={gridElStyle}>
              <SlotCard year={sp.year} semester={sp.type} slotIndex={slot.sequence} displaySlot={slot} />
            </CardContainer>
          ) : (
            <div key={key} style={gridElStyle} />
          );
        })}
      </SlotGridRow>
    </div>
  );
};
