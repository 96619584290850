import { useLocation } from "@reach/router";
import { featureToggles } from "config/featureToggles";
import { useSelector } from "react-redux";
import { IState, UserMode } from "store/types";
import { getUserId } from "./userId";

export const usePlanPermissions = () => {
  const location = useLocation();
  const admin = useSelector((s: IState) => s.admin);
  const planIsTemplate = useSelector((s: IState) => !!s.enrollment?.plan?.template);

  const adminPath = location.pathname === "/staff" || location.pathname.startsWith("/staff/");
  const userMode = adminPath && admin ? UserMode.ADMIN : UserMode.STUDENT;

  const enrollment = useSelector((s: IState) => s.enrollment);
  const profile = useSelector((s: IState) => s.profile);
  let viewOnlyPlan = false;
  if (featureToggles.simpleAuthAccess) {
    const userId = profile ? profile.id : getUserId();
    const isStaff = profile ? profile.isStaff : false;
    const uuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;
    if (enrollment) {
      if (!uuidRegex.test(enrollment.plan.userId) && userId !== enrollment.plan.userId && !isStaff) {
        viewOnlyPlan = true;
      }
    }
  }

  return {
    userMode,
    readOnlyPlan: planIsTemplate && userMode !== UserMode.ADMIN,
    cloneable: !adminPath && planIsTemplate,
    adminPath: !!adminPath,
    planIsTemplate,
    viewOnlyPlan,
  };
};
