import React, { useCallback, useState } from "react";
import {
  IComponent,
  IEnrollment,
  ISecondaryCourse,
  ISecondaryCourseOption,
  IState,
  QualificationLevel,
} from "../../../store/types";
import { featureToggles } from "../../../config/featureToggles";
import { MenuItem, SubMenu } from "@szhsin/react-menu";
import ListItemIcon from "@mui/material/ListItemIcon";
import { brandGrey } from "../../../theme/colors";
import { Checkbox, Icon } from "@mui/material";
import { ReactComponent as MMSIcon } from "../MMS.svg";
import ListItemText from "@mui/material/ListItemText";
import {
  EligibilityValidateType,
  isDupSecondaryCourseAgainstMMSRule,
  isValidQualificationLevelCombinationRule,
} from "../../../lib/componentsEligibilityRules";
import { useDispatch, useSelector } from "react-redux";
import { changeSecondaryCoursesEnrollment } from "../../../actions/enrollment";
import { flatten, intersection } from "lodash";
import { DiplomaDialog } from "../DiplomaDialog";
import { usePlanPermissions } from "lib/usePlanPermissions";

interface IProps {
  selectedMajors: IComponent[];
  selectedMinors: IComponent[];
  enrollment: IEnrollment;
  openAutoAssignModal: (recordIds: string[]) => any;
}

export const DiplomasSubMenu = (props: IProps) => {
  const { selectedMajors, selectedMinors, enrollment, openAutoAssignModal } = props;
  const diplomas = enrollment?.allowedSecondaryCourses;
  const course = enrollment.course;
  const planId = enrollment?.plan?.id;
  const secondaryCourses = enrollment.plan?.secondaryCourses;
  const { readOnlyPlan, viewOnlyPlan } = usePlanPermissions();
  const admin = useSelector((state: IState) => state.admin!);
  const compEligibilites = admin?.compEligibilities;

  const dispatch = useDispatch();

  const [isDiplomaOnChange, setIsDiplomaOnChange] = useState(false);

  const [selectedDiploma, setSelectedDiploma] = useState<ISecondaryCourse[]>([]);

  const onChangeDiploma = useCallback(() => {
    setIsDiplomaOnChange(false);
    if (planId) {
      const mainCoursePoint = enrollment?.course?.points ?? 0;
      const allowedSecondaryCoursesIdsAndPoints = diplomas?.map((d: ISecondaryCourseOption) => ({
        recordId: d.recordId,
        points: d.points,
      }));

      const changeAction: any = dispatch(
        changeSecondaryCoursesEnrollment(planId, mainCoursePoint, selectedDiploma, allowedSecondaryCoursesIdsAndPoints),
      ) as any;

      if (Promise.resolve(changeAction) === changeAction) {
        changeAction.then((result: any) => {
          const assignableCore = result.value.assignableCore || [];
          const secondaryCoursesAndMMSIds = flatten(
            selectedDiploma.map((sc: any) => [sc.courseRecordId, ...(sc.mmsRecordIds ?? [])]),
          );
          const toAction = intersection(assignableCore, secondaryCoursesAndMMSIds);
          if (toAction.length > 0) {
            openAutoAssignModal(toAction);
          }
        });
      }
    }
  }, [diplomas, dispatch, enrollment?.course?.points, openAutoAssignModal, selectedDiploma, planId]);

  return (
    <>
      <DiplomaDialog
        open={!!isDiplomaOnChange}
        close={() => setIsDiplomaOnChange(false)}
        onChangeDiploma={onChangeDiploma}
      />
      {featureToggles.componentEligibility &&
        !readOnlyPlan &&
        !viewOnlyPlan &&
        course.qualificationType[0]?.aqfLevel !== QualificationLevel.MASTERS &&
        diplomas &&
        diplomas.length > 0 && (
          <SubMenu
            key="select-diploma-menu-item"
            overflow="auto"
            label={() => (
              <React.Fragment>
                <ListItemIcon style={{ color: brandGrey.toHexString() }}>
                  <Icon component={MMSIcon} />
                </ListItemIcon>
                <ListItemText primary="Select Diploma" />
              </React.Fragment>
            )}
          >
            {diplomas.map((d: any) => {
              const isValidQualificationLevelCombination = isValidQualificationLevelCombinationRule(
                compEligibilites,
                course,
                selectedMajors,
                d,
                EligibilityValidateType.Diploma,
              );

              const isMax = secondaryCourses?.length === 1;
              const isSelected = !!secondaryCourses?.find((s: ISecondaryCourse) => d.recordId === s.courseRecordId);
              const disabledDiploma = !isValidQualificationLevelCombination || (isMax && !isSelected);

              return (d?.allowedComponents || []).length > 0 ? (
                <SubMenu
                  key={d.recordId}
                  disabled={disabledDiploma}
                  label={() => (
                    <React.Fragment>
                      <ListItemIcon style={{ paddingLeft: "11px" }}>
                        <Checkbox
                          edge="start"
                          checked={!!secondaryCourses?.find((s: ISecondaryCourse) => d.recordId === s.courseRecordId)}
                          tabIndex={-1}
                          className={disabledDiploma ? "list-checkbox-disabled" : undefined}
                          disabled={disabledDiploma}
                          disableRipple
                          inputProps={{
                            "aria-label": `${disabledDiploma ? "Disabled" : ""} Checkbox ${d.name}`,
                          }}
                        />
                      </ListItemIcon>
                      <ListItemText primary={d.name} />
                    </React.Fragment>
                  )}
                >
                  {d?.allowedComponents?.map((iComponent: IComponent) => {
                    const isSelected = !!secondaryCourses?.find((sc: ISecondaryCourse) =>
                      sc.mmsRecordIds.includes(iComponent.recordId),
                    );
                    const isDupInMMS = isDupSecondaryCourseAgainstMMSRule(selectedMajors, selectedMinors, iComponent);
                    const disabledDiplomaMMS = disabledDiploma || (isMax && !isSelected) || isDupInMMS;
                    return (
                      <MenuItem
                        type="checkbox"
                        key={iComponent.recordId}
                        tabIndex={0}
                        checked={
                          !!secondaryCourses?.find((sc: ISecondaryCourse) =>
                            sc.mmsRecordIds.includes(iComponent.recordId),
                          )
                        }
                        aria-disabled={disabledDiplomaMMS}
                        className={disabledDiplomaMMS ? "menu-list-item-disabled" : undefined}
                        onClick={
                          disabledDiplomaMMS
                            ? undefined
                            : () => {
                                if (secondaryCourses?.length > 0) {
                                  onChangeDiploma();
                                } else {
                                  setIsDiplomaOnChange(!isDiplomaOnChange);
                                  setSelectedDiploma(() => [
                                    {
                                      courseRecordId: d.recordId,
                                      mmsRecordIds: [iComponent.recordId],
                                    },
                                  ]);
                                }
                              }
                        }
                      >
                        <ListItemIcon>
                          <Checkbox
                            edge="start"
                            checked={
                              !!secondaryCourses?.find((sc: ISecondaryCourse) =>
                                sc.mmsRecordIds.includes(iComponent.recordId),
                              )
                            }
                            tabIndex={-1}
                            className={disabledDiploma ? "list-checkbox-disabled" : undefined}
                            disableRipple
                            disabled={disabledDiploma}
                            inputProps={{
                              "aria-label": `${disabledDiploma ? "Disabled" : ""} Checkbox ${iComponent.name}`,
                            }}
                          />
                        </ListItemIcon>
                        <ListItemText primary={iComponent.name} />
                      </MenuItem>
                    );
                  })}
                </SubMenu>
              ) : (
                <MenuItem
                  type="checkbox"
                  tabIndex={0}
                  checked={!!secondaryCourses?.find((s: ISecondaryCourse) => d.recordId === s.courseRecordId)}
                  key={d.recordId}
                  aria-disabled={disabledDiploma}
                  className={disabledDiploma ? "menu-list-item-disabled" : undefined}
                  onClick={
                    disabledDiploma
                      ? undefined
                      : () => {
                          if (secondaryCourses?.length > 0) {
                            onChangeDiploma();
                          } else {
                            setIsDiplomaOnChange(!isDiplomaOnChange);
                            setSelectedDiploma(() => [
                              {
                                courseRecordId: d.recordId,
                                mmsRecordIds: [],
                              },
                            ]);
                          }
                        }
                  }
                >
                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      checked={!!secondaryCourses?.find((s: ISecondaryCourse) => d.recordId === s.courseRecordId)}
                      tabIndex={-1}
                      disableRipple
                      className={disabledDiploma ? "list-checkbox-disabled" : undefined}
                      disabled={disabledDiploma}
                      inputProps={{ "aria-label": `${disabledDiploma ? "Disabled" : ""} Checkbox ${d.name}` }}
                    />
                  </ListItemIcon>
                  <ListItemText primary={d.name} />
                </MenuItem>
              );
            })}
          </SubMenu>
        )}
    </>
  );
};
