import initialState from "store/initialState";
import { CLEAR_PROFILE, CLOSE_HINT, RESET_HINTS, SET_PROFILE } from "actions/profile";
import { HintToolTip, IProfile } from "store/types";
import { closeHint, resetHints } from "lib/hints";

const DELTA_TIME_MS = 2000;

const profile = (state = initialState.profile, action: any) => {
  switch (action.type) {
    case SET_PROFILE:
      // reset all hints for first time users
      const delta = new Date(action.payload.lastUpdatedTS).getTime() - new Date(action.payload.createdTS).getTime();
      if (delta <= DELTA_TIME_MS) {
        resetHints();
      }
      return {
        authenticated: true,
        id: action.payload._id,
        firstName: action.payload.firstName,
        isStaff: action.payload.isStaff,
        ...(delta <= DELTA_TIME_MS && { currentHint: HintToolTip.ACCOUNT_CREATE_PLAN }),
      };
    case CLEAR_PROFILE:
      return null;
    case RESET_HINTS:
      return { ...state, currentHint: HintToolTip.ACCOUNT_CREATE_PLAN } as IProfile;
    case CLOSE_HINT:
      closeHint(state?.currentHint);
      if (state?.currentHint === HintToolTip.ACCOUNT_CREATE_PLAN) {
        return { ...state, currentHint: HintToolTip.ACCOUNT_PLAN_LIST } as IProfile;
      }
      return { ...state, currentHint: null } as IProfile;
    default:
      return state;
  }
};

export default profile;
