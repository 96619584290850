import { failed, fulfilled } from "lib/promiseMiddlewareTypes";
import initialState from "store/initialState";
import { CLOSE_SNACKBAR, OPEN_SNACKBAR_WITH_ERR } from "actions/snackBar";
import { ActionType } from "redux-promise-middleware";
import { CHANGE_COURSE, CHANGE_YEAR, GET_CONFIG } from "actions/planCreationConfig";
import {
  ADD_YEAR,
  ASSIGN_SUBJECT,
  CHANGE_MMS_ENROLLMENT,
  CREATE_ENROLLMENT,
  GET_ENROLLMENT,
  REMOVE_YEAR,
  REMOVE_SUBJECT,
  AUTO_ASSIGN_CORE_SUBJECT,
  CHANGE_TEMPLATE_STATE,
  LOAD_TEMPLATE,
  RENAME_PLAN,
  CHANGE_SECONDARY_COURSES_ENROLLMENT,
  CLEAR_ENROLLMENT,
} from "actions/enrollment";
import {
  ADD_ELIGIBILITY,
  ADMIN_AUTH,
  CLONE_TEMPLATE_BY_YEAR,
  GET_ALL_ELIGIBILITY,
  REMOVE_ELIGIBILITY,
  REMOVE_TEMPLATE,
  UPDATE_ELIGIBILITY,
} from "actions/admin";

const err = (message: string, autoHideDuration = 30000) => ({
  type: "error",
  message,
  autoHideDuration,
});

const success = (message: string, autoHideDuration = 3000) => ({
  type: "success",
  message,
  autoHideDuration,
});

export const NOT_AUTHORISED_COURSE = "Not Authorised Course";

const snackBarState = (state = initialState.snackBarState || null, action: any) => {
  switch (action.type) {
    case CLOSE_SNACKBAR:
      return null;
    case OPEN_SNACKBAR_WITH_ERR:
      return err(action.payload.message);
    case failed(GET_CONFIG):
      return err("Could not load course configuration");
    case failed(GET_ENROLLMENT):
      return err("Could not retrieve enrollment");
    case failed(CREATE_ENROLLMENT):
      if (action.payload.payload.err === NOT_AUTHORISED_COURSE) {
        return err("This course is not authorised in your profile");
      } else {
        return err("Could not create a study plan");
      }
    case failed(CLEAR_ENROLLMENT):
      return err("Could not clean the plan");
    case failed(ADD_YEAR):
      return err("Could not add a year to plan");
    case failed(REMOVE_YEAR):
      return err("Could not remove a year to plan");
    case failed(CHANGE_YEAR):
      return err("Could not retrieve MMS for course");
    case failed(CHANGE_COURSE):
      return err("Could not retrieve available years for course");
    case failed(CHANGE_MMS_ENROLLMENT):
      return err("Could not change the MMS enrollment");
    case failed(CHANGE_TEMPLATE_STATE):
      return err("Could not change the template state");
    case failed(LOAD_TEMPLATE):
      return err("Could not load template");
    case failed(ASSIGN_SUBJECT):
      if (action.payload.status === 409) {
        return err("Subject is not available in the selected year.");
      } else {
        return err("Could not assign subject to plan.");
      }
    case failed(REMOVE_SUBJECT):
      return err("Could not remove subject");
    case failed(REMOVE_TEMPLATE):
      return err("Could not remove template");
    case fulfilled(REMOVE_TEMPLATE):
      return success(`Template ${action.payload.name} has been successfully removed`);
    case failed(CLONE_TEMPLATE_BY_YEAR):
      return err("Could not clone template(s)");
    case fulfilled(CLONE_TEMPLATE_BY_YEAR):
      if (action.payload.length === 1) {
        return success(`Template ${action.payload[0].name} cloned into ${action.payload[0].year}`);
      } else {
        return success(`Templates cloned into ${action.payload[0].year}`);
      }
    case failed(AUTO_ASSIGN_CORE_SUBJECT):
      return err("Could not assign core subjects");
    case failed(ADMIN_AUTH):
      return err("Invalid credentials");
    case failed(RENAME_PLAN):
      return err("Failed to rename plan");
    case failed(GET_ALL_ELIGIBILITY):
      return err("Could not retrieve eligibility");
    case failed(REMOVE_ELIGIBILITY):
      return err("Could not rename eligibility");
    case failed(UPDATE_ELIGIBILITY):
      return err("Could not update eligibility");
    case failed(ADD_ELIGIBILITY):
      return err("Could not add eligibility, Please check if it is already exists.");
    case failed(CHANGE_SECONDARY_COURSES_ENROLLMENT):
      return err("Could not add change diploma assignment.");
    default:
      // Handle unknown errors
      if (action.type && (action.type as string).endsWith(ActionType.Rejected)) {
        return err("An unknown error has occurred");
      } else {
        return state;
      }
  }
};

export default snackBarState;
