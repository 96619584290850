import React, { useCallback } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useDispatch, useSelector } from "react-redux";
import { IState, ITemplateSummary, TemplateStage, YearIntake } from "store/types";
import { useNavigate } from "@reach/router";
import { clearEnrollment, ICreatePlanPayload } from "../../actions/enrollment";
import { openSnackBarWithError } from "../../actions/snackBar";
import { setSubmittingChanges, STOP_SUBMITTING } from "../../actions/submittingChanges";
import { featureToggles } from "../../config/featureToggles";
import { getAllCompEligibility, getAllDiscontinuedMMS } from "../../actions/admin";

interface IProps {
  open: boolean;
  close: () => any;
  templateSummary?: ITemplateSummary;
}

export interface INewNameFormVals {
  name: string;
}

export const ClearAllTemplateDialog = ({ open, close }: IProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const enrollment = useSelector((s: IState) => s.enrollment);
  const admin = useSelector((state: IState) => state.admin);

  const onSubmit = useCallback(() => {
    if (enrollment) {
      if (featureToggles.componentEligibility) {
        dispatch(getAllCompEligibility());
      }
      if (featureToggles.discontinuedMMS) {
        dispatch(getAllDiscontinuedMMS());
      }
      const payload: ICreatePlanPayload = {
        planName: enrollment?.plan.name,
        planCourse: enrollment?.plan.courseRecordId,
        planCourseLevel: enrollment?.course?.qualificationType[0]?.studyLevel,
        planYear: enrollment?.plan.year.toString(),
        yearIntake: enrollment?.plan.isMidyearEntry ? YearIntake.MID_YEAR : YearIntake.START_YEAR,
        template: enrollment?.plan.template,
        streamRecordId: enrollment?.plan.stream?.recordId,
        entryRecordId: enrollment?.plan.entry?.recordId,
      };

      if (payload.template) {
        payload.template = {
          user: admin?.user,
          state: TemplateStage.DRAFT,
        };
      }

      const submitResult = dispatch(clearEnrollment(enrollment.plan.id || "", payload as ICreatePlanPayload)) as any;
      submitResult
        .then((response: any) => {
          navigate(
            !!payload.template
              ? `/staff/template/${response.action.payload.plan.id}`
              : `/plan/${response.action.payload.plan.id}`,
          );
        })
        .catch(() => dispatch(openSnackBarWithError(`Could not clear all for plan.`)))
        .finally(() => dispatch(setSubmittingChanges(STOP_SUBMITTING)));
      enrollment.isNewCreation = false;
    }
    close();
  }, [admin?.user, close, dispatch, enrollment, navigate]);

  return (
    <Dialog
      open={open}
      onClose={close}
      aria-labelledby="clear-all-plan-dialog-title"
      aria-describedby="clear-all-plan-dialog-description"
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle id="clear-all-plan-dialog-title">Clear plan</DialogTitle>
      <DialogContent>
        <p>
          This will reset your plan by removing all non-core subjects as well as any Major/Minor/Specialisation.
          Proceed?
        </p>
      </DialogContent>
      <DialogActions>
        <Button onClick={close} variant="contained">
          Cancel
        </Button>
        <Button color="primary" variant="contained" autoFocus onClick={onSubmit}>
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
};
