import { post, get, put, $delete } from "lib/fetch";
import store from "store";
import {
  BreadthDisciplineScope,
  IPlanTemplate,
  IRuleInvocation,
  ISecondaryCourse,
  IState,
  Semester,
  TemplateStage,
  YearIntake,
} from "store/types";
import difference from "lodash/difference";
import { INewNameFormVals } from "containers/planAction/ChangeNameDialog";
import { featureToggles } from "config/featureToggles";
import { INotesFormVals } from "../containers/planAction/EditNotesDialog";
import { getPlanHiddenMMS } from "lib/components";
import { getAssignedCourses } from "lib/assignment";
import { getAllKnownSubjects, getSubject, isSubjectAssignable } from "lib/subjects";

export const CREATE_ENROLLMENT = "enrollment/CREATE_ENROLLMENT";
export const CLEAR_ENROLLMENT = "enrollment/CLEAR_ENROLLMENT";
export const GET_ENROLLMENT = "enrollment/GET_ENROLLMENT";
export const RENAME_PLAN = "enrollment/RENAME";
export const EDIT_NOTES = "enrollment/EDIT_NOTES";
export const ADD_YEAR = "enrollment/ADD_YEAR";
export const REMOVE_YEAR = "enrollment/REMOVE_YEAR";
export const FLIP_STUDY_PERIOD = "enrollment/FLIP_STUDY_PERIOD";
export const FLIP_SELF_EVAL_SELECTION = "enrollment/FLIP_SELF_EVAL_SELECTION";
export const CHANGE_MMS_ENROLLMENT = "enrollment/CHANGE_MMS_ENROLLMENT";
export const ASSIGN_SUBJECT = "enrollment/ASSIGN_SUBJECT";
export const REMOVE_SUBJECT = "enrollment/REMOVE_SUBJECT";
export const REMOVE_ENROLLMENT = "enrollment/REMOVE_ENROLLMENT";
export const CHANGE_SUBJECT_TYPE_ASSIGNMENT = "enrollment/CHANGE_SUBJECT_TYPE_ASSIGNMENT";
export const AUTO_ASSIGN_CORE_SUBJECT = "enrollment/AUTO_ASSIGN_CORE_SUBJECT";
export const CHANGE_TEMPLATE_STATE = "enrollment/CHANGE_TEMPLATE_STATE";
export const LOAD_TEMPLATE = "enrollment/LOAD_TEMPLATE";

export const CLOSE_HINT = "enrollment/CLOSE_HINT";
export const RESET_HINTS = "enrollment/RESET_HINTS";
export const SET_SUBJECT_SEARCH_OPEN_STATE = "enrollment/SET_SUBJECT_SEARCH_OPEN_STATE";
export const GET_REFERRED_TO_SUBJECTS = "enrollment/GET_REFERRED_TO_SUBJECTS";
export const CHANGE_SECONDARY_COURSES_ENROLLMENT = "enrollment/CHANGE_SECONDARY_COURSE_ENROLLMENT";
export const ADD_TERM_OVERLOAD = "enrollment/ADD_TERM_OVERLOAD";
export const REMOVE_TERM_OVERLOAD = "enrollment/REMOVE_TERM_OVERLOAD";
export const FLIP_SHOW_PLACEHOLDERS = "enrollment/FLIP_SHOW_PLACEHOLDERS";

export interface ICreatePlanPayload {
  planName: string;
  planCourseLevel: string;
  planCourse: string;
  planYear: string;
  isMidyearEntry?: boolean;
  yearIntake: YearIntake | "";
  template?: IPlanTemplate;
  isNewCreation?: boolean;
  streamRecordId?: string;
  entryRecordId?: string;
}

export interface IUpdateYearPayload {
  planId: string;
  year?: number;
}

export const createEnrollment = (payload: ICreatePlanPayload) => {
  const isMidyearEntry = featureToggles.dynamicYearIntake
    ? payload.yearIntake === YearIntake.MID_YEAR
    : payload.isMidyearEntry;
  return {
    type: CREATE_ENROLLMENT,
    payload: post("/v1/enrollment", {
      ...payload,
      isMidyearEntry,
      planYear: parseInt(payload.planYear),
    }),
  };
};

export const clearEnrollment = (planId: string, payload: ICreatePlanPayload) => {
  const isMidyearEntry = featureToggles.dynamicYearIntake
    ? payload.yearIntake === YearIntake.MID_YEAR
    : payload.isMidyearEntry;
  return {
    type: CLEAR_ENROLLMENT,
    payload: put(`/v1/enrollment/${planId}/clear`, {
      ...payload,
      isMidyearEntry,
      planYear: parseInt(payload.planYear),
    }),
  };
};

export const getEnrollment = (planId: string) => {
  return {
    type: GET_ENROLLMENT,
    payload: get(`/v1/enrollment/${planId}`),
  };
};

export const removeEnrollment = (planId: string) => {
  return {
    type: REMOVE_ENROLLMENT,
    payload: $delete(`/v1/enrollment/${planId}`),
  };
};

export const renamePlan = (planId: string, payload: INewNameFormVals) => {
  return {
    type: RENAME_PLAN,
    payload: put(`/v1/enrollment/${planId}/rename`, payload),
  };
};

export const editNotesToPlan = (planId: string, payload: INotesFormVals) => {
  return {
    type: EDIT_NOTES,
    payload: put(`/v1/enrollment/${planId}/editNotes`, payload),
  };
};

export const addYear = (payload: IUpdateYearPayload) => {
  return {
    type: ADD_YEAR,
    payload: put(`/v1/enrollment/${payload.planId}/changeYears`, { action: "add" }),
  };
};

export const removeYear = (payload: IUpdateYearPayload) => {
  if (payload.year) {
    return {
      type: REMOVE_YEAR,
      payload: put(`/v1/enrollment/${payload.planId}/changeYears`, {
        action: "remove",
        year: payload.year,
      }),
    };
  }
};

export const flipStudyPeriod = (planId: string, year: number, studyterm: string) => {
  const urlQueryParams = `year=${year}&studyterm=${studyterm}`;
  return {
    type: FLIP_STUDY_PERIOD,
    payload: put(`/v1/enrollment/${planId}/flipStudyPeriod?${urlQueryParams}`, null),
  };
};

export const flipSelfEvalSelection = (planId: string, payload: { ruleId: string; recordId: string }) => {
  return {
    type: FLIP_SELF_EVAL_SELECTION,
    payload: put(`/v1/enrollment/${planId}/flipSelfEvalSelection`, payload),
  };
};

export const changeMMSEnrollment = (planId: string, changingMMSRecordIds: string[]) => {
  const { enrollment } = store.getState() as IState;
  const planMMRecordIds = enrollment?.plan.mmsRecordIds || [];
  const newMMSRecordIds = [
    ...difference(planMMRecordIds, changingMMSRecordIds),
    ...difference(changingMMSRecordIds, planMMRecordIds),
  ];

  return {
    type: CHANGE_MMS_ENROLLMENT,
    payload: put(`/v1/enrollment/${planId}/changeMMSEnrollment`, { mmsRecordIds: newMMSRecordIds }),
  };
};

export const changeSecondaryCoursesEnrollment = (
  planId: string,
  mainCoursePoint: number,
  secondaryCourses: ISecondaryCourse[],
  allowedSecondaryCoursesIdsAndPoints?: any,
) => {
  const { enrollment } = store.getState() as IState;
  const planSecondaryCourses = enrollment?.plan.secondaryCourses || [];

  let newSecondaryCourses: ISecondaryCourse[] = [];
  if (planSecondaryCourses.length === 0) {
    newSecondaryCourses = secondaryCourses;
  }

  return {
    type: CHANGE_SECONDARY_COURSES_ENROLLMENT,
    payload: put(`/v1/enrollment/${planId}/changeSecondaryCoursesEnrollment`, {
      planId,
      mainCoursePoint,
      secondaryCourses: newSecondaryCourses,
      allowedSecondaryCoursesIdsAndPoints,
    }),
  };
};

export const assignSubject = (assignment: {
  planId: string;
  courseRecordId: string;
  subjectRecordId: string;
  // If we want to enforce a specific MMS for the assigned subject
  mmsRecordId?: string;
  courseRecordIds?: string[];
  year: number;
  semester: Semester;
  slotIndex: number;
  // If we want to enforce breadth vs disipline
  assignAs?: BreadthDisciplineScope;
}) => {
  const { planId, ...payload } = assignment;
  const state = store.getState() as IState;
  const { enrollment } = state;
  const courseRef = enrollment?.course ? { code: enrollment.course.code, name: enrollment.course.name } : null;
  const defaultMMS = getPlanHiddenMMS(enrollment?.plan, enrollment?.allowedComponents);

  // Ensure the subject is assigned to a proper course
  const s = getSubject(getAllKnownSubjects(state), assignment.subjectRecordId);
  const assignedCourses = getAssignedCourses(enrollment);
  const courseRecordIds: string[] = [];
  for (const cid of assignment.courseRecordIds ?? []) {
    const course = assignedCourses.find((c) => c.recordId === cid);
    if (course?.recordId && isSubjectAssignable(course?.code, s)) {
      courseRecordIds.push(course?.recordId);
    }
  }
  if (courseRecordIds.length === 0) {
    for (const course of assignedCourses) {
      if (isSubjectAssignable(course?.code, s)) {
        courseRecordIds.push(course.recordId);
        break;
      }
    }
  }

  return {
    type: ASSIGN_SUBJECT,
    payload: put(`/v1/enrollment/${planId}/assignSubject`, {
      ...payload,
      mmsRecordId: defaultMMS && !payload.mmsRecordId ? defaultMMS.recordId : payload.mmsRecordId,
      ...(courseRecordIds.length === 0 ? {} : { courseRecordIds }),
      courseRef,
    }),
  };
};

export const removeSubject = (planId: string, year: number, semester: Semester, slotIndex: number) => {
  return {
    type: REMOVE_SUBJECT,
    payload: put(`/v1/enrollment/${planId}/removeSubject`, {
      year,
      semester,
      slotIndex,
    }),
  };
};

export const changeAssignment = (
  planId: string,
  subjectRecordId: string,
  courseRecordIds: string[],
  newMMSRecordId?: string | null,
  canBeDiscipline?: boolean,
) => {
  const mmsRecordId = newMMSRecordId;
  const { enrollment } = store.getState() as IState;
  const defaultMMS = getPlanHiddenMMS(enrollment?.plan, enrollment?.allowedComponents);

  return {
    type: ASSIGN_SUBJECT,
    payload: put(`/v1/enrollment/${planId}/changeAssignment`, {
      subjectRecordId,
      courseRecordIds,
      canBeDiscipline,
      mmsRecordId: defaultMMS && !mmsRecordId ? defaultMMS.recordId : mmsRecordId,
    }),
  };
};

export const changeSubjectTypeAssignment = (planId: string, subjectRecordId: string, isDiscipline: boolean) => {
  return {
    type: CHANGE_SUBJECT_TYPE_ASSIGNMENT,
    payload: put(`/v1/enrollment/${planId}/changeSubjectTypeAssignment`, {
      subjectRecordId,
      isDiscipline,
    }),
  };
};

export const autoAssignComponentSubjects = (planId: string | undefined, recordIds: string[]) => {
  return {
    type: AUTO_ASSIGN_CORE_SUBJECT,
    payload: put(`/v1/enrollment/${planId}/assignComponentCoreSubjects`, {
      recordIds,
    }),
  };
};

export const assignInvocationCoreSubjects = (
  planId: string | undefined,
  invocation: IRuleInvocation,
  courseCode: string | undefined,
) => {
  const { enrollment } = store.getState() as IState;
  const defaultMMS = getPlanHiddenMMS(enrollment?.plan, enrollment?.allowedComponents);

  return {
    type: AUTO_ASSIGN_CORE_SUBJECT,
    payload: put(`/v1/enrollment/${planId}/assignInvocationCoreSubjects`, {
      invocation,
      courseCode,
      defaultMMSRecordId: defaultMMS?.recordId ?? null,
    }),
  };
};

export const closeCurrentHint = () => ({
  type: CLOSE_HINT,
  payload: {},
});

export const resetAllHints = () => ({
  type: RESET_HINTS,
  payload: {},
});

export const setSubjectSearchOpenState = (open: boolean) => ({
  type: SET_SUBJECT_SEARCH_OPEN_STATE,
  payload: { open },
});

const searchByRecordIdsAndCodes = async (recordIds: string[], codes: string[], year?: string | number) => {
  const [res1, res2] = await Promise.all([
    recordIds.length > 0 ? get(`/v1/subject?year=${year}&recordIds=${JSON.stringify(recordIds)}`) : Promise.resolve([]),
    codes.length > 0 ? get(`/v1/subject?year=${year}&codes=${JSON.stringify(codes)}`) : Promise.resolve([]),
  ]);
  return [...(res1.results || res1), ...(res2.results || res2)];
};

export const loadReferredSubjects = (recordIds: string[], codes: string[]) => {
  const { enrollment } = store.getState() as IState;
  const referredSubjects = enrollment?.referredSubjects || [];
  // Get the correct year
  const year = enrollment?.plan?.year;
  const misingRecordIds = recordIds.filter((ri) => !referredSubjects.find((s) => s.recordId === ri));
  const missingCodes = codes.filter((c) => !referredSubjects.find((s) => s.code === c));

  return {
    type: GET_REFERRED_TO_SUBJECTS,
    payload: searchByRecordIdsAndCodes(misingRecordIds, missingCodes, year),
  };
};

export const changeTemplateState = (state: TemplateStage, planId: string) => {
  return {
    type: CHANGE_TEMPLATE_STATE,
    payload: put(`/v1/enrollment/${planId}/changeTemplateState`, { state }),
  };
};

export const loadTemplate = (planId: string, templateId: string) => {
  return {
    type: LOAD_TEMPLATE,
    payload: put(`/v1/enrollment/${planId}/loadTemplate`, { templateId }),
  };
};

export const addOverload = (planId: string, period: { type: Semester; year: number }) => {
  return {
    type: ADD_TERM_OVERLOAD,
    payload: get(`/v1/enrollment/${planId}`).then((enrollment) => ({ enrollment, period })),
  };
};

export const removeOverload = (period: { type: Semester; year: number }) => {
  return {
    type: REMOVE_TERM_OVERLOAD,
    payload: period,
  };
};

export const flipShowPlaceholders = (planId: string, showPlaceholders: boolean) => {
  return {
    type: FLIP_SHOW_PLACEHOLDERS,
    payload: put(`/v1/enrollment/${planId}/flipShowPlaceholders?showPlaceholders=${showPlaceholders}`, null),
  };
};
